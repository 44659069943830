import React, {useEffect, useState, useMemo} from 'react';
import {Route, useHistory, Switch} from 'react-router-dom';
import ProtectedRoute from "./components/protectedRoute";
import {UserContext} from './context/userContext';
import {DataProvider} from "./context/dataContext";
import * as Constants from './constants'
import {API, setToken, deleteToken, getToken, initAxiosInterceptors} from './http-common';

import './App.css';

import Login from './components/Login/Login'
import Applicants from './components/Panel/Applicants/Applicants';
import Approve from './components/Panel/Approve/Approve';
import CreateNewSearch from './components/Panel/CreateNewSearch/CreateNewSearch';
import NewApplicant from './components/Panel/NewApplicant/NewApplicant';
import Panel from './components/Panel/Panel';
import Report from './components/Panel/Report/Report';
import Searches from './components/Panel/Searches/Searches';
import TimeLineRRHH from './components/Panel/TimeLineRRHH/TimeLineRRHH';
import InfoApplicant from './components/Panel/Applicants/InfoApplicant/InfoApplicant';
import ApplicantInfo from './components/Panel/Applicants/ApplicantInfo';
import Compensation from './components/Panel/Approve/Compensation/Compensation';
import NewApplicantPublic from "./components/Panel/NewApplicant/NewApplicantPublic"
import Loading from './components/Panel/Loading/Loading';
import AbmUsers from './components/Panel/ABMUsers/AbmUsers';
import ABMCompanies from './components/Panel/ABMCompanies/ABMCompanies';
import Hunt from './components/Panel/hunt/Hunt';
import NewHunt from './components/Panel/NewHunt/NewHunt';

import { useSnackbar } from 'react-simple-snackbar'
import CompleteRegister from './components/Panel/NewApplicant/CompleteRegister/CompleteRegister';
import LoginCallback from './components/Login/login-callback';
import ABMNamePosition from './components/Panel/ABMNamePosition/ABMNamePosition';

initAxiosInterceptors();

function App() {
    const [authUrl, setAuthUrl] = useState('/auth/init');
    const [authType, setAuthType] = useState(null);
    const [defaultUser, setDefaultUser] = useState("")
    const [nonce, setNonce] = useState('');

    const options = {
    style: {
        backgroundColor: '#c60000',
        color: 'white',
        borderRadius: '10px',
        fontSize: '18px',
        textAlign: 'center',
    },
    closeStyle: {
        fontSize: '16px',
    },
    }

    let history = useHistory();
    const [error, setError] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isLogged, setIsLogged] = useState(null);

    const [openSnackbar, closeSnackbar] = useSnackbar(options)

    const ProviderUserData = useMemo(() => ({userData, setUserData, setIsLogged}), [userData, setUserData, setIsLogged]);

    async function login(user, pass = null) {
        if(authType === null) {
            // testExternal: 1
            API.post(authUrl, {user: user })
            .then(function (response) {
                const data = response.data
                if([0, 1].includes(data.tipoAutenticacion)){
                    setAuthType(data.tipoAutenticacion)
                    setAuthUrl('/auth/login')
                    setDefaultUser(data.user)
                    setNonce(data.nonce)
                }else{
                    // data.URLAuth
                    window.location.href = data.URLAuth;
                }
            })
            .catch(e => {
                if(e.response.status === 429) {
                    openSnackbar('Demasiadas solicitudes, intente nuevamente en unos minutos')
                }
                else {
                    openSnackbar(e.response.data.message)
                    setIsLogged(false)
                    }
            })

        }

        if([0,1].includes(authType)) {


            const data = {
                user: user,
                pass: pass,
                nonce: nonce
            }

            API.post(authUrl, data)
            .then(function (response) {
                setToken(response.data.accessToken);
                setUserData(response.data.data);
                setIsLogged(true);
                setAuthType(null)
                setNonce(null)
                setAuthUrl('/auth/init')
                history.push("/");
            })
            .catch(async (e) => {
                if(e.response.status === 429) {
                    openSnackbar('Demasiadas solicitudes, intente nuevamente en unos minutos')
                }
                else {
                openSnackbar(e.response.data.message)
                setIsLogged(false)
                }

                const newInstance = await API.post('/auth/init', {user: user });
                setNonce(newInstance.data.nonce)
            })
        }
    }

    function showError(message) {
        setError(message);
    }

    function hideError() {
        setError(null);
    }

    const backAction = () => {
        setAuthType(null)
        setNonce(null)
        setAuthUrl('/auth/init')
    }

    // Se llama cada vez que el componente hace render
    useEffect(() => {
        const loadUser = async () => {
            if (!getToken()) {
                setUserData(null);
                setIsLogged(false);
                return;
            }
            try {
                const resp = await API.get('/auth/session');
                setToken(resp.data.accessToken);
                setUserData(resp.data.data);
                setIsLogged(true)

            } catch (error) {
                setUserData(null);
                setIsLogged(false);
            }
        }
        loadUser();
    }, []);



    if (isLogged === null) {
        return <Loading />
        // <div style={{textAlign: 'center', marginLeft: '5vw'}}>
        //     'Loading...'
        // </div>
    } else {
        return (
            <>
                <Switch>
                    <Route exact path='/aplicar/:id' component={NewApplicantPublic} />
                    <Route exact path='/datos-ingreso/:application_id/:candidate_id/:hash' component={CompleteRegister} />
                    <Route exact path='/datos-ingreso' component={CompleteRegister} />
                    <UserContext.Provider value={ProviderUserData}>
                        <Route exact path="/login/sso-callback" render={props => <LoginCallback {...props} showError={showError}/>}/>
                        <Route exact path="/login" render={props => <Login {...props} login={login} authType={authType} defaultUser={defaultUser} backAction={backAction} showError={showError}/>}/>
                        <ProtectedRoute exact path={["/","/panel"]} component={Panel} allowedRoles={Constants.RoleAll} isLogged={isLogged}/>
                        <ProtectedRoute path="/busqueda" component={Searches} allowedRoles={Constants.Manager} isLogged={isLogged}/>
                        <ProtectedRoute path="/crear_busqueda" component={CreateNewSearch} allowedRoles={Constants.Manager} isLogged={isLogged}/>
                        <ProtectedRoute exact path="/candidatos" component={Applicants} allowedRoles={Constants.RoleAll}  isLogged={isLogged}/>
                        <ProtectedRoute exact path="/candidatos/:id" component={ApplicantInfo} allowedRoles={Constants.RoleAll}  isLogged={isLogged}/>
                        <ProtectedRoute exact path="/candidatos/info" component={InfoApplicant} allowedRoles={Constants.RoleAll}  isLogged={isLogged}/>
                        <ProtectedRoute exact path="/hunt" component={Hunt} allowedRoles={Constants.RRHH}  isLogged={isLogged}/>
                        <ProtectedRoute exact path="/hunt/:id" component={NewHunt} allowedRoles={Constants.RRHH}  isLogged={isLogged}/>
                        <ProtectedRoute path="/crear_candidato" component={NewApplicant} allowedRoles={Constants.Manager}  isLogged={isLogged}/>
                        <ProtectedRoute path="/crear_precandidato" component={NewHunt} allowedRoles={Constants.RRHH}  isLogged={isLogged}/>
                        <ProtectedRoute path="/reporte" component={Report} allowedRoles={Constants.Manager}  isLogged={isLogged}/>
                        <ProtectedRoute path="/aprobar_busqueda" component={Approve} allowedRoles={Constants.Director}  isLogged={isLogged}/>
                        <ProtectedRoute path="/abm_usuarios" component={AbmUsers} allowedRoles={Constants.Analyst}  isLogged={isLogged}/>
                        <ProtectedRoute path="/abm_empresas" component={ABMCompanies} allowedRoles={Constants.Analyst}  isLogged={isLogged}/>
                        <ProtectedRoute path="/abm_name_positions" component={ABMNamePosition} allowedRoles={Constants.Analyst}  isLogged={isLogged}/>

                        <DataProvider>
                            <ProtectedRoute exact path="/time_line_rrhh/:id" component={TimeLineRRHH} allowedRoles={Constants.RoleAll}  isLogged={isLogged}/>
                            {/* <ProtectedRoute exact path="/time_line_rrhh/evaluacion" component={ApplicantEvaluation} allowedRoles={Constants.RoleAll}  isLogged={isLogged}/> */}
                            <ProtectedRoute exact path="/time_line_rrhh/evaluacion" render={props => console.log(props)} allowedRoles={Constants.RoleAll}  isLogged={isLogged}/>
                        </DataProvider>
                        <ProtectedRoute exact path="/compensacion/:id" component={Compensation} allowedRoles={Constants.RoleAll}  isLogged={isLogged}/>
                    </UserContext.Provider>
                </Switch>
            </>
        );
    }
}

export default App;
