import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/userContext";
// import Footer from "../includes/Footer/Footer"
import Header from "../includes/Header/Header";
import "./panel.css";
import * as Constants from "../../constants";

const Panel = () => {
  const { userData, setUserData } = useContext(UserContext);
  const role = userData.role_id;
  const ROLE_PSYCH = 5;

  useEffect(() => {
    let candidateUrl = localStorage.getItem("candidateUrl");
    if (candidateUrl) {
      window.location.replace(candidateUrl);
    }
  }, []);
  return (
    <>
      <Header />
      <div className="content-body">
        <div className="contenido">
          {Constants.Manager.includes(role) && (
            <Link to="/crear_busqueda">
              <div className="modulo" id="crear-busqueda">
                <span>Crear Nueva Búsqueda</span>
                <div className="barra"></div>
              </div>
            </Link>
          )}
          {Constants.Manager.includes(role) && (
            <Link to="/busqueda">
              <div className="modulo" id="busqueda">
                <span>Búsquedas</span>
                <div className="barra"></div>
              </div>
            </Link>
          )}
          {Constants.RoleAll.includes(role) && (
            <Link to="/candidatos">
              <div className="modulo" id="candidatos">
                <span>Candidatos</span>
                <div className="barra"></div>
              </div>
            </Link>
          )}
          {Constants.Manager.includes(role) && (
            <Link to="/crear_candidato">
              <div className="modulo" id="crear-candidato">
                <span>Crear Nuevo Candidato</span>
                <div className="barra"></div>
              </div>
            </Link>
          )}
          {role !== ROLE_PSYCH && (
            <Link to="/reporte">
              <div className="modulo" id="reporte">
                <span>Reportes</span>
                <div className="barra"></div>
              </div>
            </Link>
          )}
          {Constants.Director.includes(role) && (
            <Link to="/aprobar_busqueda">
              <div className="modulo" id="aprobar">
                <span>Aprobar Búsqueda</span>
                <div className="barra"></div>
              </div>
            </Link>
          )}
          {Constants.RRHH.includes(role) && (
            <Link to="/abm_usuarios">
              <div className="modulo" id="abmusuarios">
                <span>ABM Usuarios</span>
                <div className="barra"></div>
              </div>
            </Link>
          )}
          {Constants.RRHH.includes(role) && (
            <Link to="/abm_empresas">
              <div className="modulo" id="abmusuarios">
                <span>ABM Empresas</span>
                <div className="barra"></div>
              </div>
            </Link>
          )}
          {Constants.RRHH.includes(role) && (
            <Link to="/abm_name_positions">
              <div className="modulo" id="abmusuarios">
                <span>ABM Nombre de posiciones</span>
                <div className="barra"></div>
              </div>
            </Link>
          )}

          {Constants.RRHH.includes(role) && (
            <Link to="/hunt">
              <div className="modulo" id="hunteo">
                <span>HUNTEO</span>
                <div className="barra"></div>
              </div>
            </Link>
          )}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Panel;
