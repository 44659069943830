import React, { useEffect, useState, useMemo } from "react";
import { Button, Form, Modal, Spinner, Alert } from 'react-bootstrap';
import styled from "styled-components";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import Footer from "../../includes/Footer/Footer";
import Header from "../../includes/Header/Header";
import Menu from "../../includes/Menu/Menu";
import "./ABMUser.css";
import { API } from "../../../http-common";
import LoadingList from "../Loading/LoadingList";
import '.././../ModalGenericoBalanz/ModalBalanz.css'
import editar from "../../../images/menu/editarblack.png";
import trash from "../../../images/menu/delete.png";


function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <input
      className="form-control search"
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Buscar`}
    />
  );
}

const Styles = styled.div`
  display: block;
  max-width: 100%;
  .tableWrap {
    display: block;
    max-width: 100%;
    border-bottom: 1px solid black;
  }
  table {
    width: 100%;
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      width: 1%;
      &.collapse {
        width: 0.0000000001%;
      }
      :last-child {
        border-right: 0;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }
  .closeButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`;

function Table({ columns, data, setShowModal, setShowModalConfirm }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Styles>
      <div className="tableWrap">
        <div className="d-flex">
          <div className="botones">
            <button type="button" onClick={() => setShowModal(true)}>Alta de Posición</button>
          </div>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        <table className="table table_busqueda" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination"> {/* Usamos un div para el mejor estilo */}
          <ul className="pagination">
            <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <button className="page-link" >Primera</button>
            </li>
            <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
              <button className="page-link" >&lt; Anterior</button>
            </li>
            <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
              <button className="page-link" >Siguiente &gt;</button>
            </li>
            <li className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              <button className="page-link" >Última</button>
            </li>
            <li>
              <button className="page-link no-hover" >
                Página <strong>{pageIndex + 1} de {pageCount}</strong>
              </button>
            </li>
            <li className="page-item">
              <input
                className="page-link no-hover"
                type="number"
                defaultValue={pageIndex + 1}
                min="1"
                max={pageCount}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "80px", textAlign: "center" }}
              />
            </li>
            <li className="page-item">
              <select
                className="form-select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                style={{ fontSize: "15px", width: "120px", padding: "7px" }}
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </select>
            </li>
          </ul>
        </div>
      </div>
    </Styles>
  );
}

const ABMNamePosition = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'Nombre',
      },
      {
        accessor: 'description',
        Header: 'Descripción',
        Cell: ({row}) => row.original.description ? 'Descripción disponible' : 'Sin descripción' 
      },
      {
        Header: 'Acciones',
        Cell: ({ row }) => (
          <>
            <button className="closeButton" onClick={() => handleEdit(row.original)}>
              <img
                style={{ margin: "0 0 0 0" }}
                width={20}
                src={editar}
                alt="pen"
              />
            </button>{' '}
            <button className="closeButton" onClick={() => handleDelete(row.original.id)}>
              <img
                style={{ margin: "0 0 0 0" }}
                width={20}
                src={trash}
                alt="trash"
              />
            </button>
          </>
        ),
      },
    ],
    []
  );
  
  const handleEdit = (item) => {
    setIsEditing(true);
    setCurrentItem({ ...item });
    setShowModal(true);
  };

  const handleDelete = (id) => {
    setCurrentItem({ id });
    setShowModalConfirm(true);
  };

  const confirmDelete = async () => {
    setLoading(true);
    try {
      await API.post(`/name-position/delete/${currentItem.id}`); 
      setData(data.filter((item) => item.id !== currentItem.id));
      setShowModalConfirm(false);
      getNamePositions();
    } catch (error) {
      console.error('Error deleting position:', error);
      setError('Error deleting position');
    } finally {
      setLoading(false);
    }
  };
  const handleClosetModal = () => {
    setShowModal(false);
    setCurrentItem({});
    setIsEditing(false);
    setError(null);
  }

  const getNamePositions = async () => {
    setIsLoadingTable(true);
    try {
      const response = await API.get("/name-position");
     if(response.data.error === 0){
      setData(response.data.positions);
      }
    } catch (error) {
      console.error(error);
      setError('Error fetching positions');
    } finally {
      setIsLoadingTable(false);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (isEditing) {
        await API.post(`/name-position/update/${currentItem.id}`, currentItem);
        setData(
          data.map((item) =>
            item.id === currentItem.id ? currentItem : item
          )
        );
      } else {
        const response = await API.post('/name-position/create', currentItem);
        setData([
          ...data,
          { ...currentItem, id: response.data.id },
        ]);
      }
      setShowModal(false);
      getNamePositions();
      setError(null);
      setCurrentItem({});
    } catch (error) {
      console.error('Error updating position:', error);
      setError(error.response?.data?.error || 'Error updating position');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNamePositions();
  }, []);

  return (
    <>
      <Header />
      <Menu />
      <div className="content-candidatos">
        <div className="contenido">
          <div className="content-table">
            {isLoadingTable ? (
              <LoadingList />
            ) : (
              <Table
                columns={columns}
                data={data}
                setShowModal={setShowModal}
                setShowModalConfirm={setShowModalConfirm}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />

      <Modal show={showModal} onHide={handleClosetModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Editar' : 'Agregar'} Posición</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner
              animation="border"
              style={{
                margin: '20px auto',
                display: 'flex',
                width: '5rem',
                height: '5rem',
              }}
            />
          ) : (
            <Form>
              <Form.Group controlId="formBasicName">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre de la Posición"
                  value={currentItem.name || ''}
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, name: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicDescription">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Descripción de la Posición"
                  value={currentItem.description || ''}
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, description: e.target.value })
                  }
                />
              </Form.Group>
            </Form>
          )}

          {!loading && error && <Alert variant="danger">{error}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosetModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalConfirm}  onHide={handleClosetModal}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Posición</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spinner
              animation="border"
              style={{
                margin: '20px auto',
                display: 'flex',
                width: '4rem',
                height: '4rem',
              }}
            />
          ) : (
            <p>¿Estás seguro que deseas eliminar la Posición?</p>
          )}
          {!loading && error && <Alert variant="danger">{error}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosetModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={confirmDelete}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ABMNamePosition;