import editar from '../../../../images/menu/editar.png'
import { API } from '../../../../http-common'
import React, { useEffect, useState, useContext } from "react"
import '../timeLineRRHH.css'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import $ from 'jquery'
import { UserContext } from '../../../../context/userContext';
import { useSnackbar } from 'react-simple-snackbar'
import LoadingList from '../../Loading/LoadingList';
import { useHistory } from 'react-router-dom';
import { PRODUCTION_URL, RRHH } from '../../../../constants';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Alert, Button } from 'react-bootstrap';
import { Modal, Row, Col } from "react-bootstrap";
import { ROLE_HR, ROLE_DIRECTOR_RRHH, ROLE_HIM_RRHH } from '../../../../constants';

const TimeLineSearch = (req) => {

  const options = {
    style: {
      backgroundColor: '#0078c6',
      color: 'white',
      borderRadius: '10px',
      fontSize: '18px',
      textAlign: 'center',
    },
    closeStyle: {
      fontSize: '16px',
    },
  }
  const allowedRoles = [ROLE_HR, ROLE_DIRECTOR_RRHH, ROLE_HIM_RRHH];
  const { userData, setUserData } = useContext(UserContext)
  const [openSnackbar, closeSnackbar] = useSnackbar(options)
  const [showModal, setShowModal] = useState(false)
  const [showModalOnIntranet, setShowModalOnIntranet] = useState(false)

  // const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [vacancies, setVacancies] = useState([]);
  const [seniorities, setSeniorities] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [managements, setManagements] = useState([]);
  const [costCenter, setCostCenter] = useState('');
  const [idDepartament, setIdDepartament] = useState('')
  const [id_departament_intranet, setid_departament_intranet] = useState('')
  const [email, setEmail] = useState('');

  const [editDescription, setEditDescription] = useState(false)

  let history = useHistory()

  const idSearch = req.search.id
  const departaments = req.departaments;
  const departaments_intranet = req.departaments_intranet;
  const userDataRole = userData.role_id
  const [search, setSearch] = useState([])
  const [namePosiciones, setNamePosiciones] = useState([]); 
  const [selectedPositionId, setSelectedPositionId] = useState(''); 
  const [description_task_disabled, setdescription_task_disabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [gerenteModified, setGerenteModified] = useState('')
  const [directorModified, setDirectorModified] = useState('')
  const [nameJobModified, setNameJobModified] = useState('')
  const [seniorityModified, setSeniorityModified] = useState('')
  // const [vacancieModified, setVacancieModified] = useState('')
  const [descriptionTaskModified, setDescriptionTaskModified] = useState('')
  const [descriptionProfileModified, setDescriptionProfileModified] = useState('')

  const [idDepartamentError, setIdDepartamentError] = useState(false)
  const [idDepartamentWarning, setIdDepartamentWarning] = useState('')

  const getSearche = () => {
    setIsLoading(true)
    API.get('/positions/' + idSearch)
      .then(function (response) {
        if (response.data.error === 0) {
          setSearch(response.data.data)
          setIsLoading(false)
        } else {
          //todo
        }
      })
  }

  const getVacancies = () => {
    API.get('/positions/vacancies')
      .then(function (response) {
        if (response.data.error === 0) {
          setVacancies(response.data.data)
        } else {
          //todo
        }
      })
  }

  const getNamePositions = () => {
    API.get('/name-position') 
        .then(response => {
            if (response.data.error === 0) {
                setNamePosiciones(response.data.positions);
            } else {
                console.error("Error HTTP:", response.status, response.statusText);
                openSnackbar(`Error al obtener puestos: ${response.statusText}`);
            }
        })
        .catch(error => {
            console.error("Error al obtener puestos:", error);
            openSnackbar(`Error al obtener puestos: ${error.message}`);
        });
};

  const getSeniorities = () => {
    API.get('/employees/seniorities')
      .then(function (response) {
        if (response.data.error === 0) {
          setSeniorities(response.data.data)
        } else {
          //todo
        }
      })
  }

  const getDirectors = () => {
    API.get('/employees/directors')
      .then(function (response) {
        if (response.data.error === 0) {
          setDirectors(response.data.data)
        } else {
          //todo
        }
      })
  }

  const getManagements = () => {
    API.get('/employees/managements')
      .then(function (response) {
        if (response.data.error === 0) {
          setManagements(response.data.data)
        } else {
          //todo
        }
      })
  }

  const handleCancelChanges = () => {
    setEditDescription(false)
  }

  const onFinalizeSearch = () => {
    // history.push('/busqueda')
    API.put(`positions/finish/${idSearch}`)
      .then(response => {
        history.push('/busqueda')
      })
  }

  //Envio de postulación en corporativo Balanz.com
  const setCorporateStatus = (corporate, id_departament, modal = null) => {
    const data = {
      corporate: corporate,
      id_departament: id_departament
    }
    API.post(`positions/corporate/status/${search.id}`, data)
      .then(response => {
        if (response.data.error === 0) {
          const find = departaments.find(d => d.id_departament.toString() === id_departament);
          setSearch(old => ({
            ...old,
            corporate,
            departament: find ? find.departament : ''
          }))


          if (null !== modal) {
            modal(false)
          }
        }
      })
  }
  const setIntranetStatus = (intranet, id_departament_intranet, modal = null) => {
    const data = {
      intranet: intranet,
      id_departament_intranet: id_departament_intranet
    }
    API.post(`positions/intranet/status/${search.id}`, data)
      .then(response => {
        if (response.data.error === 0) {
          const find = departaments_intranet.find(d => d.id_departament_intranet.toString() === id_departament_intranet)
          setSearch(old => ({
            ...old,
            intranet,
            departament_intranet: find ? find.departament_intranet : ''
          }))
          if (null !== modal) {
            modal(false)
          }
        }
        else {
          console.log(response.data)
        }
      })



  }

  const onChangePauseSearch = () => {
    API.put(`positions/toggle_satus/${idSearch}`)
      .then(response => {
        setEditDescription(true)
        setEditDescription(false)
      })
  }

  const handleSubmitChanges = () => {
    const data = {
      description_task: descriptionTaskModified,
      profile_description: descriptionProfileModified,
      report_to: gerenteModified,
      director_id: directorModified,
      seniority_id: seniorityModified,
      position_name: nameJobModified,
      selectedPositionId: selectedPositionId,
      started_email: email
    }

    API.put(`/positions/${idSearch}/update`, data)
    .then(response => {
      setEditDescription(false);
      setLoading(false);
    })
    .catch(error => {
      setLoading(false);
      if (error.response && error.response.status === 400) {
        setError(error.response.data.message);
      } else {
        setError('Ocurrió un error. Por favor, inténtelo de nuevo más tarde.');
      }
    });
  }

  const getCostCenter = () => {
    let text = '';
    managements.map(item => {
      if (item.id === search.report_to) {
        text = item.cost_center
      }
    });
    return text
  }

  const toggleEditSearch = () => {
    setEditDescription(true)
    setGerenteModified(search.report_to)
    setSeniorityModified(search.seniority_id)
    setDirectorModified(search.director_id)
    setNameJobModified(search.position_name)
    setCostCenter(getCostCenter())
    setDescriptionTaskModified(search.description_task)
    setDescriptionProfileModified(search.profile_description)
    setEmail(search.started_email)
  }

  const setStateCorporate = () => {
    if (idDepartament === '') {
      setIdDepartamentError(true)
      setIdDepartamentWarning('Debe seleccionar un departamento')
      return;
    }

    setCorporateStatus(1, idDepartament, setShowModal);
  }

  const setStateIntranet = () => {
    if (id_departament_intranet === '') {
      setIdDepartamentError(true)
      setIdDepartamentWarning('Debe seleccionar un departamento')
      return;
    }

    setIntranetStatus(1, id_departament_intranet, setShowModalOnIntranet);
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
};

  useEffect(() => {
    getSearche();
    getVacancies();
    getSeniorities();
    getDirectors();
    getManagements();
    getNamePositions();
  }, [editDescription])

  // if (isLoading) { return (<div style={{textAlign: 'center', marginLeft: '5vw'}}>Cargando...</div>)
  if (isLoading) {
    return (<LoadingList />)
  } else {
    return (
      <div className="form-busqueda">
        {userData.role_id !== 5 && <span className="editar" onClick={() => toggleEditSearch()}>EDITAR BÚSQUEDA <img src={editar} alt="" /></span>}
        {/* <span className="editar" onClick={() => setEditDescription(true)}>EDITAR BÚSQUEDA <img src={editar} alt="" /></span> */}
        <span className="datos">BÚSQUEDA {search.id} <b>{search.position_name}</b></span>
        <hr className="doble" />
        {
          !allowedRoles.includes(userData.role_id) && (
            <>
              <span className="datos">e-mail <b>{search.started_email}</b></span>
              <hr />
            </>
          )
        }
        {!editDescription ?
          <>
            {
              allowedRoles.includes(userData.role_id) && (
                <>
                  <span className="datos">e-mail <b>{search.started_email}</b></span>
                  <hr />
                </>
              )
            }
            <span className="datos">¿Reportará a otra persona? <b>{search.him_name}</b></span>
            <hr />
            <span className="datos">Director que aprueba la vacante <b>{search.director_username}</b></span>
            <hr />
            <span className="datos">Sector <b>{search.him_sector}</b></span>
            <hr />
            <span className="datos">Centro de costos asociado a la vacante <b>{search.him_cost_center}</b></span>
            <hr />
            <span className="datos">Nombre del puesto <b>{search.position_name}</b></span>
            <hr />
            <span className="datos">Seniority <b>{search.sniority_name}</b></span>
            <hr />
            <div className="descripcion">
              <span>Descripción de las tareas a realizar</span>
              <p>{search.description_task}</p>
            </div>
            <hr />
            <div className="descripcion">
              <span>Descripción del perfil requerido: estudios, experiencia previa, etc.</span>
              <p>{search.profile_description}</p>
            </div>
            <hr />
            <span className="datos">La vacante se genera a partir de <b>{search.vacancy_name}</b></span>
            <hr />
            <div className="botones">
              {search.status !== 5 && (
                <>
                  <DropdownButton id="copiar" title="COPIAR LINK DE BÚSQUEDA">
                    <CopyToClipboard text={`${PRODUCTION_URL}/aplicar/${search.id}`} >
                      <Dropdown.Item>COPIAR LINK DE BÚSQUEDA</Dropdown.Item>
                    </CopyToClipboard>
                    <CopyToClipboard text={`${PRODUCTION_URL}/aplicar/${search.id}?origen=Linkedin`} >
                      <Dropdown.Item>COPIAR LINK DE BÚSQUEDA PARA LINKEDIN</Dropdown.Item>
                    </CopyToClipboard>
                  </DropdownButton>

                  <button id="finalizar" onClick={() => $('#finalizar-busqueda').modal('show')} >
                    FINALIZAR BÚSQUEDA
                  </button>
                </>
              )}
              {search.status === 3 ?
                <button id="pausar" onClick={() => $('#pausar-reanudar-busqueda').modal('show')}>
                  PAUSAR BÚSQUEDA
                </button>
                :
                <button id="aprobar" className="aprobar" onClick={() => $('#pausar-reanudar-busqueda').modal('show')}>
                  REANUDAR BÚSQUEDA
                </button>
              }
            </div>
            <div className='row publicar-busqueda-container'>
              { RRHH.includes(userData.role_id) && (
                <>
                  {search.status === 3 && search.status_rrhh === 3 && search.status_director === 3 && search.corporate === 0 && (
                    <Alert variant="secondary" >
                      <Alert.Heading as="h5" className='mb-3'>Publicar en balanz.com</Alert.Heading>
                      <p>Haga clic en el botón para publicar esta búsqueda en el sitio corporativo</p>
                      <Button onClick={() => setShowModal(true) /*setCorporateStatus(1)*/} variant="outline-secondary">
                        Publicar en balanz.com
                      </Button>
                    </Alert>
                  )}

                  {search.status === 3 && search.status_rrhh === 3 && search.status_director === 3 && search.corporate === 1 && (
                    <Alert variant="success" >
                      <Alert.Heading as="h5" className='mb-3'>Publicar en balanz.com</Alert.Heading>
                      <p className='mb-2'>La búsqueda está publicada en balanz.com</p>
                      <p><b>Departamento: </b>{search.departament}</p>
                      <Button onClick={() => setCorporateStatus(0, null)} variant="outline-success">
                        Anular publicación
                      </Button>
                    </Alert>
                  )}
                </>
              )}
              {RRHH.includes(userData.role_id) && (
                <>
                  {search.status === 3 && search.status_rrhh === 3 && search.status_director === 3 && (
                    <Alert variant={search.intranet === 1 ? 'success' : search.intranet === 2 ? 'danger' : 'secondary'}>
                      <Alert.Heading as="h5" className='mb-3'>Publicar en Intranet</Alert.Heading>
                      {
                        <>
                          {search.intranet === 1 && (
                            <>
                            <p className='mb-2'>La búsqueda está publicada en la Intranet</p>
                            <p><b>Departamento: </b>{search.departament_intranet}</p>
                            </>
                          )}
                          {search.intranet === 2 && (
                            <p className='mb-2'>La búsqueda está Finalizada (Histórico)</p>
                          )}
                          {search.intranet !== 1 && search.intranet !== 2 && (
                            <p>Haga clic en el botón para publicar esta búsqueda en la Intranet</p>
                          )}
                        </>
                      }

                        <DropdownButton id="acciones-intranet" title="ACCIONES INTRANET" variant={search.intranet === 1 ? 'success' : 'secondary'}>
                          {search.intranet !== 1 && <Dropdown.Item onClick={() => setShowModalOnIntranet(true)}>PUBLICAR BÚSQUEDA</Dropdown.Item>}
                          {(search.intranet !== 0 && search.intranet !== 2 && search.intranet !== 2 ) && <Dropdown.Item onClick={() => setIntranetStatus(0, null)}>DESPUBLICAR BÚSQUEDA</Dropdown.Item>}
                          {search.intranet === 1 &&  <Dropdown.Item onClick={() => setIntranetStatus(2)}>FINALIZAR BÚSQUEDA (HISTÓRICO) </Dropdown.Item>}
                        </DropdownButton>
                    </Alert>
                  )}
                </>
              )}
            </div>


          </>
          :
          <>
            <div className='linea'>
            {allowedRoles.includes(userData.role_id) && (
                    <>
                        <span className="datos">e-mail</span>
                        <input value={email} name="email" onChange={handleEmailChange} type="text" />
                        <hr />
                    </>
                )}
              <span className="datos">¿Reportará a otra persona?</span>

              <select value={gerenteModified} defaultValue={gerenteModified} name="report_to" onChange={(e) => {
                setGerenteModified(e.target.value);
                managements.map((item, i) => {
                  if (item.id == e.target.value) {
                    setCostCenter(item.cost_center)
                  }
                });
              }} id="gerente">
                <option value="">Gerente</option>
                {managements.map((item, i) => <option key={i} value={item.id}>{item.username}</option>)}
              </select>
            </div>
            <hr />
            <div className='linea'>
              <span className="datos">Director que aprueba la vacante</span>
              <select value={directorModified} defaultValue={directorModified} name="report_to" onChange={(e) => setDirectorModified(e.target.value)} id="gerente">
                <option value="">Director</option>
                {directors.map((item, i) => <option key={i} value={item.id}>{item.username}</option>)}
              </select>
            </div>
            <hr />
            <span className="datos">Sector <b>{costCenter}</b></span>
            <hr />
            <span className="datos">Centro de costos asociado a la vacante <b>{costCenter}</b></span>
            <hr />
            <div className='linea'>
              <span className="datos">Nombre del puesto</span>
                        <select
                            value={selectedPositionId}
                            onChange={(e) => {
                                const selectedId = e.target.value;
                                setSelectedPositionId(selectedId);
                                const selectedPosition = namePosiciones.find(p => p.id === parseInt(selectedId));
                                if (selectedPosition) {
                                    setNameJobModified(selectedPosition.name);
                                    if (selectedPosition.description) {
                                        setDescriptionTaskModified(selectedPosition.description);
                                        setdescription_task_disabled(true);
                                    } else {
                                        setDescriptionTaskModified('');
                                        setdescription_task_disabled(false);
                                    }
                                } else {
                                    setNameJobModified('');
                                    setDescriptionTaskModified('');
                                    setdescription_task_disabled(false);
                                }
                            }}
                        >
                            <option value="">
                              {
                                search.position_name ? search.position_name : 'Seleccione un puesto'
                              }
                              </option>
                            {namePosiciones.map((position, index) => (
                                <option key={index} value={position.id}>{position.name}</option>
                            ))}
                        </select>
            </div>
            <hr />
            <div className='linea'>
              <span className="datos">Seniority </span>
              <select value={seniorityModified} defaultValue={seniorityModified} name="report_to" onChange={(e) => setSeniorityModified(e.target.value)} id="gerente">
                <option value="">Seniority</option>
                {seniorities.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
              </select>
            </div>
            <hr />
            <div className="content-feedback">
            <span>Descripción de las tareas a realizar</span>
            <textarea
              id="feedback"
              cols="30"
              rows="10"
              value={descriptionTaskModified}
              onChange={e => setDescriptionTaskModified(e.target.value)}
            />
            </div>
            <hr />
            <div className="content-feedback">
              <span>Descripción del perfil requerido: estudios, experiencia previa, etc.</span>
              <textarea id="feedback" cols="30" rows="10" placeholder=""
                // className={feedbackError ? 'input-error' : ''}
                defaultValue={descriptionProfileModified}
                value={descriptionProfileModified}
                onChange={e => setDescriptionProfileModified(e.target.value)}>
              </textarea>

            </div>
            <hr />
            <span className="datos">La vacante se genera a partir de <b>{search.vacancy_name}</b></span>
            <hr />
            <div className="botones">
              <button id="guardar" onClick={() => $('#guardar-cambios-busqueda').modal('show')}>
                GUARDAR CAMBIOS
              </button>
              <button id="cancelar" onClick={() => $('#cancelar-cambios-busqueda').modal('show')}>
                CANCELAR
              </button>
            </div>

          </>
        }

        {/* Modal de finalizar busqueda */}
        <div className="modal fade" id="finalizar-busqueda" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div className="modal-body ">
                <span className="texto-general">¿Estas seguro de finalizar esta búsqueda?</span>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" id="ok_avanza" data-dismiss="modal" onClick={() => onFinalizeSearch()}>Ok</button>
                <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal de pausar/reiniciar cambios de busqueda */}
        <div className="modal fade" id="pausar-reanudar-busqueda" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div className="modal-body ">
                <span className="texto-general">¿Estas seguro de cambiar el status de la búsqueda?</span>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" id="ok_avanza" data-dismiss="modal" onClick={() => onChangePauseSearch()}>Ok</button>
                <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal de guardar cambios de busqueda */}
        <div className="modal fade" id="guardar-cambios-busqueda" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div className="modal-body ">
                <span className="texto-general">¿Estas seguro de guardar estos cambios?</span>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" id="ok_avanza" data-dismiss="modal" onClick={handleSubmitChanges}>Ok</button>
                <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal de cancelar cambios */}
        <div className="modal fade" id="cancelar-cambios-busqueda" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
              </div>
              <div className="modal-body ">
                <span className="texto-general">¿Estas seguro de cancelar estos cambios?</span>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" id="ok_avanza" data-dismiss="modal" onClick={handleCancelChanges}>Ok</button>
                <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
              </div>
            </div>
          </div>
        </div>


        <Modal //Modal de Balanz.com
          // size="sm"
          show={showModal}
          onHide={() => {
            setShowModal(false)
          }}
          className="modal_check_edit_applicant"
        // aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header className="modal-header" closeButton></Modal.Header>
          <Modal.Body>
            <Modal.Title className="titulo mb-1">SELECCIONE EL DEPARTAMENTO AL QUE PERTENECE ESTA BÚSQUEDA.</Modal.Title>
            <Row>
              <Col xs={12}>
                <div className="w-100 content-datos">
                  <select onChange={
                    (e) => {
                      setIdDepartamentError(false);
                      setIdDepartament(e.target.value);
                    }}
                    value={idDepartament}
                    name="id_departament"
                    id="id_departament"
                  >
                    <option value="">Selecione una opcion</option>
                    {departaments.map((item, i) => <option key={i} value={item.id_departament}>{item.departament}</option>)}
                  </select>
                  <div className={`alert alert-danger mt-3 ${idDepartamentError ? "" : "d-none"}`}>{idDepartamentWarning}</div>
                </div>
              </Col>

              {RRHH.includes(userData.role_id) && (
                <Col xs={12} className='text-center botones align-items-center justify-content-center'>
                  <button
                    id="copiar"
                    onClick={setStateCorporate}
                  >
                    Publicar en balanz.com
                  </button>

                  <button
                    onClick={() => { setShowModal(false) }}
                    id="finalizar"
                  >
                    CANCELAR
                  </button>
                </Col>
              )}

            </Row>
          </Modal.Body>
        </Modal>

        <Modal //Modal de Intranet
          // size="sm"
          show={showModalOnIntranet}
          onHide={() => {
            setShowModalOnIntranet(false)
          }}
          className="modal_check_edit_applicant"
        // aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header className="modal-header" closeButton></Modal.Header>
          <Modal.Body>
            <Modal.Title className="titulo mb-1">SELECCIONE EL DEPARTAMENTO AL QUE PERTENECE ESTA BÚSQUEDA.</Modal.Title>
            <Row>
              <Col xs={12}>
                <div className="w-100 content-datos">
                  <select onChange={
                    (e) => {
                      setIdDepartamentError(false);
                      setid_departament_intranet(e.target.value);
                    }}
                    value={id_departament_intranet}
                    name="id_departament_intranet"
                    id="id_departament_intranet"
                  >
                    <option value="">Selecione una opcion</option>
                    {departaments_intranet.map((item, i) => <option key={i} value={item.id_departament_intranet}>{item.departament_intranet}</option>)}
                  </select>
                  <div className={`alert alert-danger mt-3 ${idDepartamentError ? "" : "d-none"}`}>{idDepartamentWarning}</div>
                </div>
              </Col>

              {RRHH.includes(userData.role_id) && (
                <Col xs={12} className='text-center botones align-items-center justify-content-center'>
                  <button
                    id="copiar"
                    onClick={setStateIntranet}
                  >
                    Publicar en Intranet
                  </button>

                  <button
                    onClick={() => { setShowModalOnIntranet(false) }}
                    id="finalizar"
                  >
                    CANCELAR
                  </button>
                </Col>
              )}

            </Row>
          </Modal.Body>
        </Modal>
        {error && (
          <div className="modal fade show" id="errorModal" tabIndex="-1" role="dialog" aria-labelledby="errorModalLabel" aria-hidden="true" style={{ display: 'block' }}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => setError(null)}>&times;</button>
                </div>
                <div className="modal-body">
                  <span 
                    style={{
                      textAlign: 'center',
                      fontSize: '18px',
                      
                    }}
                  >{error}</span>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => setError(null)}>Cerrar</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default TimeLineSearch